import React, { useState } from 'react';
import './contact.css';

const Contact = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [submitted, setSubmitted] = useState(false); // State for form submission confirmation

  const handleSubmit = (e) => {
    e.preventDefault();
    // Here you would typically handle form submission,
    // e.g., sending the form data to your backend server or an API
    console.log('Form submitted:', { name, email, message });

    // Simulate form submission success
    // Replace with actual submission logic as needed
    // For demonstration purposes, we'll just set submitted to true
    setSubmitted(true);

    // Clear form fields
    setName('');
    setEmail('');
    setMessage('');
  };

  return (
    <div className="contact-container">
      <div className="header">
        Contact Us
      </div>
      <div className="contact">
        <h1>Contact Us</h1>
        <div className="contact-info">
          <h2>Our Office</h2>
          <p>1234 Mutual Fund Lane</p>
          <p>Finance City, 56789</p>
          <p>Email: support@yourcompany.com</p>
          <p>Phone: (123) 456-7890</p>
        </div>
        <div className="contact-form">
          {submitted ? (
            <p className="submit-message">Your message has been submitted!</p>
          ) : (
            <form onSubmit={handleSubmit}>
              <label htmlFor="name">Name:</label>
              <input
                type="text"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <label htmlFor="message">Message:</label>
              <textarea
                id="message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                rows="6"
                required
              ></textarea>
              <button type="submit">Submit</button>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default Contact;
