import React from 'react';
import './aboutus.css';

const AboutUs = () => {
  return (
    <div className="about-us-container">
      <div className="header">
       <p> CRM for Mutual Fund Distributors</p>
      </div>
      <div className="about-us">
        <h1>About Us</h1>
        <p>
          Welcome to [Your Company Name], your trusted partner in mutual fund distribution. Our mission is to provide seamless and efficient Customer Relationship Management (CRM) solutions tailored specifically for mutual fund distributors. We understand the unique challenges you face and are dedicated to helping you manage your prospects, clients, and tasks with ease.
        </p>
        <h2>Our Services</h2>
        <ul>
          <li>
            <strong>Prospects Management:</strong> Easily add and organize your prospects list. Track potential clients and ensure you never miss an opportunity to convert them into loyal customers.
          </li>
          <li>
            <strong>Client Management:</strong> Keep a comprehensive and updated list of your clients. Access detailed information at your fingertips and maintain strong relationships with your existing clientele.
          </li>
          <li>
            <strong>Lead Management:</strong> Keep a comprehensive and updated list of your leads. Access detailed information at your fingertips and maintain strong relationships with your existing clientele.
          </li>
          <li>
            <strong>Task Management:</strong> Assign tasks to specific clients and prospects. Stay on top of your commitments with our intuitive task management system, ensuring timely follow-ups and actions.
          </li>
        </ul>
        <p>
          At [Your Company Name], we strive to empower mutual fund distributors with the tools they need to enhance productivity and foster long-term client relationships. Our platform is designed with your success in mind, providing user-friendly and robust features to streamline your daily operations.
        </p>
        <p>
          Join us today and take the first step towards transforming your mutual fund distribution business.
        </p>
      </div>
    </div>
  );
};

export default AboutUs;
