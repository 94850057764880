import React from 'react';
import './benefits.css';

const Benefits = () => {
  return (
    <section id="benefits">
      <div className="container">
        {/* <h2>BENEFITS OF CRM</h2>
        <p>Discover how CRM can transform your business.</p> */}
        <div className="benefits-list">
          <div className="benefit">
            <h3>Improved Customer Relationships</h3>
            <p>Build and maintain stronger relationships with your customers.</p>
          </div>
          <div className="benefit">
            <h3>Increased Efficiency</h3>
            <p>Automate routine tasks and streamline your workflows.</p>
          </div>
          {/* Add more benefits as needed */}
          {/* <div className="free-plan">
    <h3>Free Plan 6</h3>
    <div className="price">$0<span>/month</span></div>
    <ul>
      <li>Basic features included</li>
      <li>Up to 600 contacts</li>
      <li>Limited email support</li>
    </ul>
    <button className="cta">Sign Up for Free</button>
  </div> */}
  <div className="benefit">
            <h3>Streamlined Operations</h3>
            <p>Automates routine tasks such as data entry, follow-up reminders, and report generation, increasing operational efficiency.
</p>
          </div>


        </div>
      </div>
    </section>
  );
};

export default Benefits;