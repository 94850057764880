import React, { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../header/header";
import { UserContext } from "../context/usercontext";
import './registration.css';

const apipath = process.env.REACT_APP_API_URL;


const RegistrationPage = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [city, setCity] = useState('');
    const [error, setError] = useState('');
    const { setUsername } = useContext(UserContext); // Use setUsername from context
    const navigate = useNavigate();

    const handleRegistration = async () => {
        setError(''); // Clear previous errors

        // Input validations
        if (!email || !email.includes("@")) {
            setError("Please enter a valid email address");
            return;
        }
        if (!password || password.length < 6) {
            setError("Password must be at least 6 characters long");
            return;
        }
        if (password !== confirmPassword) {
            setError("Passwords do not match");
            return;
        }

        // Try registering the user
        try {
            const response = await fetch(`${apipath}/register.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ name, email, password, phoneNumber, city }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json(); // Parse JSON response
            console.log("Response Data=", data);

            if (data.success) {
                if (typeof setUsername === 'function') {
                    setUsername(name); // Set the username in the context
                } else {
                    console.error('setUsername is not a function');
                }
                //alert(data.message);
                navigate('/login');
            } else {
                setError(data.message);
            }
        } catch (error) {
            console.error('Error during registration:', error);
            setError('Failed to register. Please try again later.');
        }
    };

    return (
        <>
            <Header />
            <div className="registration-page">
                <h2>Register</h2>
                {error && <div className="error-message">{error}</div>}
                <input
                    type="text"
                    placeholder="Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
                <input
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <input
                    type="password"
                    placeholder="Confirm Password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <input
                    type="tel"
                    placeholder="Phone Number"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                />
                <input
                    type="text"
                    placeholder="City"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                />
                <button onClick={handleRegistration}>Register</button>
                <Link to="/login">Already have an account? Login</Link>
            </div>
        </>
    );
};

export default RegistrationPage;
