import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './lead.css'; // Importing CSS file for styling
import AddLeadForm from './addleadform';
import EditLeadForm from './editleadform';
const apipath = process.env.REACT_APP_API_URL;
const Leads = () => {
    const [leads, setLeads] = useState([]);
    const [showAddForm, setShowAddForm] = useState(false);
    const [editingLead, setEditingLead] = useState(null);

    // Fetch Leads
    const fetchLeads = async () => {
        try {
            const response = await axios.get('${apipath}/leads');
            console.log('Fetched Leads:', response.data);

            if (Array.isArray(response.data)) {
                setLeads(response.data);
            } else {
                console.error('Expected an array but got:', response.data);
                setLeads([]);
            }
        } catch (error) {
            console.error('Error fetching leads:', error);
            setLeads([]);
        }
    };

    // Fetch leads when component mounts
    useEffect(() => {
        fetchLeads();
    }, []);

    // Handle adding a new lead
    const handleAddLead = async (newLead) => {
        try {
            const response = await axios.post('${apipath}/leads', newLead);
            const addedLead = response.data.lead;
            setLeads(prevLeads => [...prevLeads, addedLead]);
            setShowAddForm(false);
        } catch (error) {
            console.error('Error adding lead:', error);
        }
    };

    // Handle updating a lead
    const handleUpdateLead = async (updatedLead) => {
        try {
            const response = await axios.put(`${apipath}/leads/${updatedLead._id}`, updatedLead);
            const updatedLeadData = response.data.lead;
            setLeads(prevLeads =>
                prevLeads.map(lead =>
                    lead._id === updatedLeadData._id ? updatedLeadData : lead
                )
            );
            setEditingLead(null);
        } catch (error) {
            console.error('Error updating lead:', error);
        }
    };

    // Handle deleting a lead
    const handleDeleteLead = async (leadId) => {
        const confirmed = window.confirm('Are you sure you want to delete this lead?');
        if (!confirmed) {
            return;
        }

        try {
            await axios.delete(`${apipath}/leads/${leadId}`);
            setLeads(prevLeads => prevLeads.filter(lead => lead._id !== leadId));
        } catch (error) {
            console.error('Error deleting lead:', error);
        }
    };

    // Handle moving a lead to clients
    const handleMoveToClient = async (leadId) => {
        try {
            const response = await fetch(`${apipath}//clients.php?action=move-to-client&id=${leadId}`, {
                method: 'PUT'
            });
    
            const result = await response.json();
            if (result.client) {
                console.log('Lead moved to client:', result.client);
                // Optionally, update your UI to reflect the change
            } else {
                console.error('Error moving lead to client:', result.message);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };
    
    return (
        <div className="leads-container">
            <h1>Leads</h1>
            <div className="leads-table-container">
                {!editingLead && (
                    <button onClick={() => setShowAddForm(!showAddForm)}>Add Lead</button>
                )}
                {showAddForm && !editingLead && (
                    <AddLeadForm onAddLead={handleAddLead} />
                )}
                {editingLead && (
                    <EditLeadForm
                        lead={editingLead}
                        onUpdate={handleUpdateLead}
                        onCancel={() => setEditingLead(null)}
                    />
                )}
                <table>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Phone</th>
                            <th>City</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Array.isArray(leads) && leads.length > 0 ? (
                            leads.map(lead => (
                                lead && (
                                    <tr key={lead._id}>
                                        <td>{lead.name || 'N/A'}</td>
                                        <td>{lead.email || 'N/A'}</td>
                                        <td>{lead.phone || 'N/A'}</td>
                                        <td>{lead.city || 'N/A'}</td>
                                        <td>
                                            <button onClick={(e) => {
                                                e.stopPropagation();
                                                setEditingLead(lead);
                                            }}>
                                                Edit
                                            </button>
                                            <button onClick={(e) => {
                                                e.stopPropagation();
                                                handleDeleteLead(lead._id);
                                            }}>
                                                Delete
                                            </button>
                                            <button className="move-to-client" onClick={(e) => {
                                                e.stopPropagation();
                                                handleMoveToClient(lead._id);
                                            }}>
                                                Move to Client
                                            </button>
                                        </td>
                                    </tr>
                                )
                            ))
                        ) : (
                            <tr>
                                <td colSpan="5">No leads found</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Leads;
