import React, { useState, useEffect } from 'react';
import axios from 'axios'; // Import axios for making HTTP requests
import './profile.css';  // Make sure to import the CSS file

const Profile = () => {
  const [username, setUsername] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    // Fetch the logged-in user's information from localStorage or another secure source
    const token = localStorage.getItem('token'); // Assuming the JWT is stored in localStorage

    if (token) {
      const decodedToken = JSON.parse(atob(token.split('.')[1]));
      setUsername(decodedToken.username);
    }
  }, []);

  const handlePasswordChange = async (e) => {
    e.preventDefault();

    // Validate new password and confirm password match
    if (newPassword !== confirmPassword) {
      setErrorMessage('New password and confirm password do not match');
      return;
    }

    // Clear previous messages
    setErrorMessage('');
    setSuccessMessage('');

    try {
      console.log('Sending password change request...');
      const response = await axios.post('http://localhost:5000/api/user/change-password', {
        username,
        currentPassword,
        newPassword
      });

      console.log('Response received:', response);

      if (response.status === 200) {
        setSuccessMessage('Password changed successfully');
        setCurrentPassword('');
        setNewPassword('');
        setConfirmPassword('');
      } else {
        setErrorMessage('Failed to change password. Please try again later.');
      }
    } catch (error) {
      console.error('Error during password change:', error);
      setErrorMessage(error.response?.data?.message || 'An error occurred');
    }
  };

  return (
    <div className="profile-page">
      <div>
        <form onSubmit={handlePasswordChange}>
          <div>
            <label htmlFor="currentPassword">Current Password</label>
            <input
              type="password"
              id="currentPassword"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              required
            />
          </div>
          <div>
            <label htmlFor="newPassword">New Password</label>
            <input
              type="password"
              id="newPassword"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
            />
          </div>
          <div>
            <label htmlFor="confirmPassword">Confirm New Password</label>
            <input
              type="password"
              id="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          </div>
          {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
          {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
          <button type="submit">Change Password</button>
        </form>
      </div>
    </div>
  );
};

export default Profile;
