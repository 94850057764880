import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { UserContext } from '../context/usercontext';
import logo from '../../images/logo.jpg';
import './adminheader.css'; // Corrected import

const AdminHeader = () => {
  const { name, logout } = useContext(UserContext);
  const [showSubmenu, setShowSubmenu] = useState(false);

  const toggleSubmenu = () => {
    setShowSubmenu(prevShowSubmenu => !prevShowSubmenu);
  };

  const handleLogout = () => {
    logout(); // Call the logout function from UserContext
    // Optionally, you may want to navigate the user to the login page or homepage
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showSubmenu && !event.target.closest('.username')) {
        setShowSubmenu(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showSubmenu]);

  useEffect(() => {
    console.log('Name in AdminHeader:', name); // Debugging line
  }, [name]);

  return (
    <div className="header">
      <div className="logo">
        <img src={logo} alt="GoCRM Logo" />
      </div>
      <div className="username" onClick={toggleSubmenu}>
        <p>{name || 'Guest'} <span className="dropdown-arrow">&#9660;</span></p>
        {showSubmenu && (
          <div className="submenu">
            <ul>
              <li><Link to="/admin/profile">Change Password</Link></li>
              <li><Link to="/frontpage" onClick={handleLogout}>Logout</Link></li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminHeader;
