import React, { createContext, useState, useEffect } from 'react';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [name, setName] = useState('');

  useEffect(() => {
    const storedName = localStorage.getItem('name');
    console.log('Retrieved name from localStorage in UserContext:', storedName);
    if (storedName) {
      setName(storedName);
      console.log('UserContext name state set to:', storedName);
    } else {
      console.warn('No name found in localStorage');
    }
  }, []);

  const updateName = (newName) => {
    setName(newName);
    localStorage.setItem('name', newName);
  };

  const logout = () => {
    setName('');
    localStorage.removeItem('name');
    // Additional logout logic like clearing other session data
  };

  return (
    <UserContext.Provider value={{ name, setName: updateName, logout }}>
      {children}
    </UserContext.Provider>
  );
};
