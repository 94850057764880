import React from 'react';
import './header.css';
import logo from '../../images/logo.jpg';
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <header>
      <div className="container">
        <div className="logo">
          <img src={logo} alt="GoCRM Logo" />
        </div>
        <nav>
          <ul>
            <li><a href="/pricing">Pricing</a></li>
            <li><a href="/aboutUs">About Us</a></li>
            <li><a href="/contact">Contact</a></li>
          </ul>
        </nav>
        <a href="/login" className="cta">Get Started</a>
      </div>
    </header>
  );
};

export default Header;
