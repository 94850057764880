// src/components/EditLeadForm.js

import React, { useState, useEffect } from 'react';

const EditLeadForm = ({ lead, onUpdate, onCancel }) => {
  const [name, setName] = useState(lead.name);
  const [email, setEmail] = useState(lead.email);
  const [phone, setPhone] = useState(lead.phone);
  const [city, setCity] = useState(lead.city);

  useEffect(() => {
    setName(lead.name);
    setEmail(lead.email);
    setPhone(lead.phone);
    setCity(lead.city);
  }, [lead]);

  const handleSubmit = (e) => {
    e.preventDefault();
    onUpdate({ ...lead, name, email, phone, city });
  };

  return (
    <div>
      <h2>Edit Lead</h2>
      <form onSubmit={handleSubmit}>
        <label>
          Name:
          <input type="text" value={name} onChange={(e) => setName(e.target.value)} required />
        </label>
        <label>
          Email:
          <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
        </label>
        <label>
          Phone:
          <input type="text" value={phone} onChange={(e) => setPhone(e.target.value)} required />
        </label>
        <label>
          City:
          <input type="text" value={city} onChange={(e) => setCity(e.target.value)} required />
        </label>
        <button type="submit">Update</button>
        <button type="button" onClick={onCancel}>Cancel</button>
      </form>
    </div>
  );
};

export default EditLeadForm;
