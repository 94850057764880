import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import FrontPage from './component/frontpage/frontpage';
import Footer from './component/footer/footer';
import Header from './component/header/header';
import Features from './component/Features/features';
import Hero from './component/Hero/hero';
import Benefits from './component/Benefits/benefits';
import LoginPage from './component/Login/login';
import RegistrationPage from './component/Login/registration';
import AboutUs from './component/aboutus/aboutus';
import PricingPage from './component/pricing/pricing';
import FreePricing from './component/freepricing/freepricing';
import AdminDashboard from './component/admindashboard/admindashboard';
import Clients from './component/clients/clients';
import Tasks from './component/task/task';
import { UserProvider } from './component/context/usercontext';
import ProspectDetails from './component/prospects/prospectsdetails';
import Prospects from './component/prospects/prospects';
import ProspectsTable from './component/prospects/prospectstable';
import Profile from './component/admindashboard/profile';
import AdminHeader from './component/admindashboard/adminheader';
import Dashboard from './component/admindashboard/dashboard';
import Sidebar from './component/admindashboard/sidebar';
import { UserContext } from './component/context/usercontext';
import Contact from './component/contact/contact';

import Leads from './component/leads/lead';











function App() {
  return (
    <UserProvider>
    <>
       
    <Router>
      <div className="App">
      
  
  

        <Routes>
         <Route path="/" element={<FrontPage />} />
          <Route path="/frontpage" element={<FrontPage />} />
          <Route path="/features" element={<Features />} />
          <Route path="/hero" element={<Hero />} />
          <Route path="/benefits" element={<Benefits />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/registration" element={<RegistrationPage />} />
          <Route path="/aboutUs" element={<AboutUs/>} />
          <Route path="/pricing" element={<PricingPage/>} />
          <Route path="/freepricing" element={<FreePricing/>} />
          <Route path="/contact" element={<Contact/>}/>
    

          <Route element={<AdminDashboard />}>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/prospects" element={<Prospects />} />
              <Route path="/clients" element={<Clients />} />
              <Route path="/tasks" element={<Tasks />} />
              <Route path="/lead" element={<Leads/>}/>
              
              <Route path="/profile" element={<Profile />} />
            </Route>
        </Routes>
      </div>
    </Router>
    
    </>
    </UserProvider>
  );
}

export default App;
