import React from 'react';
import './hero.css';

const Hero = () => {
  return (
    <section id="hero">
      <div className="container">
      <h2>CRM </h2>
      <h2> Mutual fund Distributor</h2>
        <h1>Revolutionize Your Customer Management</h1>
        <p>CRM - The ultimate solution to streamline your customer interactions and boost your business.</p>
        <p>Modern CRMs provide a 360-degree view of the customer, integrating data from various touchpoints to offer comprehensive insights.</p>
        <a href="/freepricing" className="cta">Try for Free</a>
      </div>
    </section>
  );
};

export default Hero;