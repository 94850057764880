import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './prospects.css';
import AddProspectForm from './addprospectform';
import EditProspectForm from './editprospectsform';
const apipath = process.env.REACT_APP_API_URL;

const Prospects = () => {
  const [prospects, setProspects] = useState([]);
  const [showAddForm, setShowAddForm] = useState(false);
  const [editingProspect, setEditingProspect] = useState(null);
  const [leads, setLeads] = useState([]);

  const fetchProspects = async () => {
    try {
      const response = await axios.get('${apipath}/prospects');
      console.log('Fetched Prospects:', response.data);
      if (Array.isArray(response.data)) {
        setProspects(response.data);
      } else {
        console.error('Expected an array but got:', response.data);
        setProspects([]); 
      }
    } catch (error) {
      console.error('Error fetching prospects:', error);
      setProspects([]); 
    }
  };

  useEffect(() => {
    fetchProspects();
  }, []);

  const handleAddProspect = async (newProspect) => {
    try {
      const response = await axios.post('${apipath}/prospects', newProspect);
      console.log('Server response:', response.data);

      if (!response.data.error) {
        const addedProspect = response.data;
        setProspects((prevProspects) => [...prevProspects, addedProspect]);
        setShowAddForm(false);
      }
    } catch (error) {
      console.error('Error adding prospect:', error.response ? error.response.data : error.message);
    }
  };

  const handleUpdateProspect = async (updatedProspect) => {
    const prospectId = updatedProspect._id || updatedProspect.id;
    try {
      const response = await axios.put(`${apipath}/prospects/${prospectId}`, updatedProspect);
      const updatedProspectData = response.data;
      setProspects((prevProspects) =>
        prevProspects.map((prospect) =>
          (prospect._id || prospect.id) === prospectId ? updatedProspectData : prospect
        )
      );
      setEditingProspect(null);
    } catch (error) {
      console.error('Error updating prospect:', error);
    }
  };

  const handleDelete = async (prospectId) => {
    try {
      await axios.delete(`${apipath}/prospects/${prospectId}`);
      setProspects((prevProspects) => prevProspects.filter((prospect) => (prospect._id || prospect.id) !== prospectId));
    } catch (error) {
      console.error('Error deleting prospect:', error);
    }
  };

  const handleMoveToLead = async (prospectId) => {
    try {
        const response = await axios.put(`${apipath}/prospects/move-to-lead/${prospectId}`);
        const movedLead = response.data.lead;
        setProspects(prevProspects => prevProspects.filter(prospect => prospect._id !== prospectId));
        console.log('Moved prospect to lead:', movedLead);
    } catch (error) {
        console.error('Error moving prospect to lead:', error);
    }
};

  const handleRowClick = (prospect) => {
    console.log('Prospect clicked:', prospect);
    setEditingProspect(prospect);
  };

  return (
    <div className="prospects-container">
      <h1>Prospects</h1>
      <div className="prospects-table-container">
        {!editingProspect && (
          <button onClick={() => setShowAddForm(!showAddForm)}>Add Prospect</button>
        )}
        {showAddForm && !editingProspect && (
          <AddProspectForm onAddProspect={handleAddProspect} />
        )}
        {editingProspect && (
          <EditProspectForm
            prospect={editingProspect}
            onUpdate={handleUpdateProspect}
            onCancel={() => setEditingProspect(null)}
          />
        )}
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>City</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {prospects.map((prospect, index) => {
              if (!prospect || !prospect.id) {
                console.error('Invalid prospect data:', prospect);
                return null;
              }

              const prospectId = prospect.id || index;
              return (
                <tr key={prospectId} onClick={() => handleRowClick(prospect)}>
                  <td>{prospect.name}</td>
                  <td>{prospect.email}</td>
                  <td>{prospect.phone}</td>
                  <td>{prospect.city}</td>
                  <td>
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        setEditingProspect(prospect);
                      }}
                    >
                      Edit
                    </button>
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDelete(prospectId);
                      }}
                    >
                      Delete
                    </button>
                    <button
                      className="move-to-lead"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleMoveToLead(prospectId);
                      }}
                    >
                      Move to Lead
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Prospects;
