import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import './login.css';
import Header from "../header/header";

const apipath = process.env.REACT_APP_API_URL;

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate(); // useNavigate hook for navigation

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      // const response = await fetch(`${apipath}/login.php`, {
      const response = await fetch(`${apipath}/login.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      if (response.ok) {
        const { token, user } = await response.json();
        localStorage.setItem('token', token);

        if (user && user.name) {
          localStorage.setItem('name', user.name);
          console.log('New user name stored in localStorage:', user.name);
        } else {
          console.warn('User object does not contain name');
        }

        console.log('Login successful');
        window.location.href = '/dashboard'; // Redirect to admin dashboard
      } else {
        const errorData = await response.json();
        setError(errorData.error || 'Failed to login. Please try again.');
      }
    } catch (error) {
      console.error('Failed to login:', error);
      setError('Failed to login. Please try again later.');
    }
  };

  return (
    <>
      <Header />
      <div className="login-container">
        <h2>Login</h2>
        {error && <div className="error-message">{error}</div>}
        <form className="login-form" onSubmit={handleLogin}>
          <div className="form-group">
            <label>Email:</label>
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label>Password:</label>
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit">Login</button>
        </form>
        <p>If you haven't registered yet, <Link to="/registration">click here to sign up</Link>.</p>
        <p><Link to="/frontpage">Go back to home page</Link></p>
      </div>
    </>
  );
};

export default LoginPage;
