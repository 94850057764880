import React, { useState } from 'react';
import axios from 'axios';

const AddProspectForm = ({ onAddProspect }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [city, setCity] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Send POST request to backend to add prospect
      const response = await axios.post('${apipath}/prospects', {
        name,
        email,
        phone,
        city
      });

      // Clear form fields after successful submission
      setName('');
      setEmail('');
      setPhone('');
      setCity('');

      // Optionally, show a success message to the user
      alert('Prospect added successfully');

      // Pass the added prospect to the parent component
      onAddProspect(response.data);
    } catch (error) {
      // Handle error, show error message to user or log it
      console.error('Error adding prospect:', error);
      alert('Error adding prospect. Please try again.');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <input
        type="text"
        placeholder="Name"
        value={name}
        onChange={(e) => setName(e.target.value)}
        required
      />
      <input
        type="email"
        placeholder="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        required
      />
      <input
        type="text"
        placeholder="Phone"
        value={phone}
        onChange={(e) => setPhone(e.target.value)}
        required
      />
      <input
        type="text"
        placeholder="City"
        value={city}
        onChange={(e) => setCity(e.target.value)}
        required
      />
      <button type="submit">Add Prospect</button>
    </form>
  );
};

export default AddProspectForm;
