import React, { useState, useEffect } from 'react';

const EditProspectForm = ({ prospect, onUpdate, onCancel }) => {
  const [name, setName] = useState(prospect.name);
  const [email, setEmail] = useState(prospect.email);
  const [phone, setPhone] = useState(prospect.phone);
  const [city, setCity] = useState(prospect.city);

  // Ensure that the form fields are updated whenever the selected prospect changes
  useEffect(() => {
    setName(prospect.name);
    setEmail(prospect.email);
    setPhone(prospect.phone);
    setCity(prospect.city);
  }, [prospect]);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Call the onUpdate function with the updated prospect data
    onUpdate({ ...prospect, name, email, phone, city });
  };

  return (
    <div>
      <h2>Edit Prospect</h2>
      <form onSubmit={handleSubmit}>
        <label>
          Name:
          <input type="text" value={name} onChange={(e) => setName(e.target.value)} required />
        </label>
        <label>
          Email:
          <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
        </label>
        <label>
          Phone:
          <input type="text" value={phone} onChange={(e) => setPhone(e.target.value)} required />
        </label>
        <label>
          City:
          <input type="text" value={city} onChange={(e) => setCity(e.target.value)} required />
        </label>
        <button type="submit">Update</button>
        <button type="button" onClick={onCancel}>Cancel</button>
      </form>
    </div>
  );
};

export default EditProspectForm;
