// src/components/AddLeadForm.js

import React, { useState } from 'react';

const AddLeadForm = ({ onAddLead }) => {
  const initialLeadState = {
    name: '',
    email: '',
    phone: '',
    city: ''
  };

  const [lead, setLead] = useState(initialLeadState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLead({ ...lead, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onAddLead(lead);
    setLead(initialLeadState); // Clear form after submission
  };

  return (
    <div>
      <h2>Add Lead</h2>
      <form onSubmit={handleSubmit}>
        <label>
          Name:
          <input type="text" name="name" value={lead.name} onChange={handleChange} required />
        </label>
        <label>
          Email:
          <input type="email" name="email" value={lead.email} onChange={handleChange} required />
        </label>
        <label>
          Phone:
          <input type="text" name="phone" value={lead.phone} onChange={handleChange} required />
        </label>
        <label>
          City:
          <input type="text" name="city" value={lead.city} onChange={handleChange} required />
        </label>
        <button type="submit">Add Lead</button>
      </form>
    </div>
  );
};

export default AddLeadForm;
