import React, { useEffect, useState } from 'react';
import './dashboard.css';
const apipath = process.env.REACT_APP_API_URL;
const Dashboard = () => {
  const [data, setData] = useState({ prospects: [], clients: [], leads: [] });

  useEffect(() => {
    // Fetch dashboard data from the API
    fetch('http://localhost/crm/api/dashboard')
      .then(response => response.json())
      .then(data => {
        console.log('Fetched data:', data); // Log fetched data
        setData(data);
      })
      .catch(error => console.error('Error fetching dashboard data:', error));
  }, []);

  const prospectCount = data.prospects?.length || 0;
  const clientCount = data.clients?.length || 0;
  const leadCount = data.leads?.length || 0;

  // if (!data.prospects?.length && !data.clients?.length && !data.leads?.length) {
  //   return (
  //     <div className="loading">
  //       {/* <p>Loading dashboard...</p> */}
  //     </div>
  //   );
  // }

  return (
    <div className="dashboard">
      <h1>Dashboard</h1>

      <section className="about-crm">
        <h2>About CRM for Mutual Fund Distributors</h2>
        <p>
          Customer Relationship Management (CRM) systems for mutual fund distributors play a crucial role 
          in managing client relationships, enhancing service delivery, and optimizing business processes. 
          These systems help distributors track investor information, manage portfolios, and improve client 
          communication through integrated tools and analytics.
        </p>
        <p>
          Effective CRM solutions enable mutual fund distributors to streamline operations, increase efficiency, 
          and provide personalized services to investors. By centralizing data and automating routine tasks, 
          distributors can focus more on client engagement and strategic growth.
        </p>
      </section>

      <section className="dashboard-stats">
        <h2>Dashboard Statistics</h2>
        <table>
          <thead>
            <tr>
              <th>Type</th>
              <th>Count</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Prospects</td>
              <td>{prospectCount}</td>
            </tr>
            <tr>
              <td>Clients</td>
              <td>{clientCount}</td>
            </tr>
            <tr>
              <td>Leads</td>
              <td>{leadCount}</td>
            </tr>
          </tbody>
        </table>
      </section>
    </div>
  );
};

export default Dashboard;
