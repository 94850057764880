import React from 'react';
import './freepricing.css';

const FreePricing = () => {
    const plans = [
        { name: "Free Plan 1", contacts: 100 },
        { name: "Free Plan 2", contacts: 200 },
        { name: "Free Plan 3", contacts: 300 },
        { name: "Free Plan 4", contacts: 400 },
        { name: "Free Plan 5", contacts: 500 },
        { name: "Free Plan 6", contacts: 600 },
    ];

    return (
        <div className="free-plans-container">
            {plans.map((plan, index) => (
                <div className="free-plan" key={index}>
                    <h3>{plan.name}</h3>
                    <div className="price">$0<span>/month</span></div>
                    <ul>
                        <li>Basic features included</li>
                        <li>Up to {plan.contacts} contacts</li>
                        <li>Limited email support</li>
                    </ul>
                    <button className="cta">Sign Up for Free</button>
                </div>
            ))}
        </div>
    );
};

export default FreePricing;
