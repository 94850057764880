import React from 'react';
import { Outlet } from 'react-router-dom';
import AdminHeader from './adminheader'; // Corrected import
import Sidebar from './sidebar'; // Assuming this is your sidebar component
import './admindashboard.css'; // Corrected import
import Dashboard from './dashboard';

const AdminDashboard = () => {
  return (
    <>
      <AdminHeader />
      <div className="admin-dashboard">
        <div className="dashboard-container">
          <Sidebar />
          <div className="dashboard-content">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminDashboard;
