import React from 'react';
import { Link } from 'react-router-dom';
import './sidebar.css';

const Sidebar = () => {
  return (
    <div className="sidebar">
      <ul>
        <li className="sidebar-item"><Link to="/dashboard">Dashboard</Link></li>
        <li className="sidebar-item"><Link to="/prospects">Prospects</Link></li>
        <li className="sidebar-item"><Link to="/lead">Leads</Link></li>
        <li className="sidebar-item"><Link to="/clients">Clients</Link></li>
        <li className="sidebar-item"><Link to="/tasks">Tasks</Link></li>
       
      </ul>
    </div>
  );
};

export default Sidebar;
