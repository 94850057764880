import React from 'react';
import logo from '../../images/logo.jpg';
import './footer.css';

const Footer = () => {
  return (
    <footer>
      <div className="container">
      {/* <div className="logo">
          <img src={logo} alt="GoCRM Logo" />
        </div> */}
            <nav>
          <ul>
            <li><a href="#terms">Terms</a></li>
            <li><a href="#privacy">Privacy</a></li>
            <li><a href="#contact">Contact</a></li>
            <li><a href="#contact">2024@Zieers system pvt Lmt</a></li>
          </ul>
        </nav>
        {/* <div className="social-media">
          <a href="#"><img src="facebook.png" alt="Facebook" /></a>
          <a href="#"><img src="twitter.png" alt="Twitter" /></a>
     </div> */}
      </div>
    </footer>
  );
};

export default Footer;