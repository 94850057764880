import React, { useState } from 'react';

const AddClientForm = ({ onAddClient }) => {
  const initialClientState = {
    name: '',
    email: '',
    phone: '',
    city: ''
  };

  const [client, setClient] = useState(initialClientState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setClient({ ...client, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onAddClient(client);
    setClient(initialClientState); // Clear form after submission
  };

  return (
    <div>
      <h2>Add Client</h2>
      <form onSubmit={handleSubmit}>
        <label>
          Name:
          <input type="text" name="name" value={client.name} onChange={handleChange} required />
        </label>
        <label>
          Email:
          <input type="email" name="email" value={client.email} onChange={handleChange} required />
        </label>
        <label>
          Phone:
          <input type="text" name="phone" value={client.phone} onChange={handleChange} required />
        </label>
        <label>
          City:
          <input type="text" name="city" value={client.city} onChange={handleChange} required />
        </label>
        <button type="submit">Add Client</button>
      </form>
    </div>
  );
};

export default AddClientForm;
