import React from 'react';
import Header from '../header/header';
import Footer from '../footer/footer';
import Features from '../Features/features';
import Hero from '../Hero/hero';
import Benefits from '../Benefits/benefits';
import './frontpage.css'; // Ensure to import the updated CSS

function FrontPage() {
  return (
    <div className="front-page">
      <Header />
      <main className="main-content">
        <h1><b>CRM Customer Relationship Management</b></h1>
        <p>
          Customer Relationship Management (CRM) is a technology for managing all your company's relationships and interactions with current and potential customers. The goal is to improve business relationships to grow your business. A CRM system helps companies stay connected to customers, streamline processes, and improve profitability.
        </p>
        <h2>Maximize your sales, we'll fill your pipeline</h2>
      </main>
      <Features />
      <Hero />
      <Benefits />
      <Footer />
    </div>
  );
}

export default FrontPage;
