import React from 'react';
import './features.css';
import feature1 from './feature1.png';
import feature2 from './feature2.png';
import feature3 from './feature3.png';




const Features = () => {
  return (
    <section id="features">
      <div className="container">
        {/* <h2>CRM </h2>
        <h2> Mutual fund Distributor</h2> */}
        <div className="features-grid">
          {/* <div className="feature">
            <img src={feature1} alt="Feature 1 " />
            <h3>Contact Management</h3>
            <p>Keep track of all your customer information in one place.</p>
          </div>
          <div className="feature">
            <img src={feature2} alt="Feature 2" />
            <h3>Sales Automation</h3>
            <p>Automate your sales process to increase efficiency and close deals faster.</p>
          </div>

          <div className="feature">
            <img src={feature3} alt="Feature 3" />
            <h3>Sales Pipeline Management</h3>
            <p>Visualize and manage the sales process by tracking leads and opportunities through various stages, from prospecting to closure, with features like pipeline views, forecasting, and sales reporting.</p>
          </div> */}
          {/* Add more features as needed */}
        </div>
      </div>
    </section>
  );
};

export default Features;