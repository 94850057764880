import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './task.css';

const Tasks = () => {
  const [selectedProspects, setSelectedProspects] = useState([]);
  const [selectedClients, setSelectedClients] = useState([]);
  const [selectedLeads, setSelectedLeads] = useState([]);
  const [subject, setSubject] = useState('');
  const [targetDate, setTargetDate] = useState('');
  const [priority, setPriority] = useState('medium');
  const [prospects, setProspects] = useState([]);
  const [clients, setClients] = useState([]);
  const [leads, setLeads] = useState([]);
  const [tasks, setTasks] = useState([]); 
  const [showProspects, setShowProspects] = useState(false);

  useEffect(() => {
    const fetchTasks = async () => {
      try {
        const response = await axios.get('${apipath}/tasks.php');
        setTasks(Array.isArray(response.data) ? response.data : []); 
      } catch (error) {
        console.error('Error fetching tasks:', error);
      }
    };

    fetchTasks();
  }, []);

  const handleProspectsClick = async () => {
    try {
      const response = await axios.get('${apipath}/tasks.php?action=get-prospects');
      console.log('Fetched prospects:', response.data); // Debugging: Check the fetched data
      setProspects(response.data || []);
      setShowProspects(true); // Show the prospects list
    } catch (error) {
      console.error('Error fetching prospects:', error);
    }
  };

  const handleProspectSelect = (event) => {
    const prospectId = event.target.value;
    const prospect = prospects.find(p => p.id === prospectId);
    if (prospect) {
      setSelectedProspects([...selectedProspects, prospect]);
    }
  };

  const handleClientSelect = (event) => {
    const clientId = event.target.value;
    const client = clients.find(c => c.id === clientId);
    if (client) {
      setSelectedClients([...selectedClients, client]);
    }
  };

  const handleLeadSelect = (event) => {
    const leadId = event.target.value;
    const lead = leads.find(l => l.id === leadId);
    if (lead) {
      setSelectedLeads([...selectedLeads, lead]);
    }
  };

  const handleSubjectChange = (event) => {
    setSubject(event.target.value);
  };

  const handleTargetDateChange = (event) => {
    setTargetDate(event.target.value);
  };

  const handlePriorityChange = (event) => {
    setPriority(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const newTask = {
      prospects: selectedProspects.map(p => p.id),
      clients: selectedClients.map(c => c.id),
      leads: selectedLeads.map(l => l.id),
      subject,
      targetDate,
      priority
    };

    try {
      await axios.post('${apipath}/tasks.php', newTask);
      //fetchTasks(); 
      resetForm(); 
    } catch (error) {
      console.error('Error creating task:', error);
    }
  };

  const resetForm = () => {
    setSelectedProspects([]);
    setSelectedClients([]);
    setSelectedLeads([]);
    setSubject('');
    setTargetDate('');
    setPriority('medium');
  };

  return (
    <div className="tasks">
      <h1>Tasks</h1>

      <div>
        <h2>Select Prospects:</h2>
        <button onClick={handleProspectsClick}>Load Prospects</button>
        {showProspects && (
          <>
            <select onChange={handleProspectSelect} value="">
              <option value="">Select Prospect</option>
              {prospects.map(prospect => (
                <option key={prospect.id} value={prospect.id}>{prospect.name}</option>
              ))}
            </select>
            <div>
              <h2>Selected Prospects:</h2>
              <input type="text" value={selectedProspects.map(p => p.name).join(', ')} readOnly />
            </div>
          </>
        )}
      </div>

      <div>
        <h2>Select Clients:</h2>
        <select onChange={handleClientSelect} value="">
          <option value="">Select Client</option>
          {clients.map(client => (
            <option key={client.id} value={client.id}>{client.name}</option>
          ))}
        </select>
        <div>
          <h2>Selected Clients:</h2>
          <input type="text" value={selectedClients.map(c => c.name).join(', ')} readOnly />
        </div>
      </div>

      <div>
        <h2>Select Leads:</h2>
        <select onChange={handleLeadSelect} value="">
          <option value="">Select Lead</option>
          {leads.map(lead => (
            <option key={lead.id} value={lead.id}>{lead.name}</option>
          ))}
        </select>
        <div>
          <h2>Selected Leads:</h2>
          <input type="text" value={selectedLeads.map(l => l.name).join(', ')} readOnly />
        </div>
      </div>

      <form onSubmit={handleSubmit}>
        <div>
          <label>Subject:</label>
          <select value={subject} onChange={handleSubjectChange} required>
            <option value="">Select Subject</option>
            <option value="call">Call</option>
            <option value="send-email">Send Email</option>
            <option value="send-quote">Send Quote</option>
            <option value="other">Other</option>
          </select>
        </div>
        <div>
          <label>Target Date:</label>
          <input type="date" value={targetDate} onChange={handleTargetDateChange} required />
        </div>
        <div>
          <label>Priority:</label>
          <select value={priority} onChange={handlePriorityChange} required>
            <option value="high">High</option>
            <option value="medium">Medium</option>
            <option value="low">Low</option>
          </select>
        </div>
        <button type="submit">Create Task</button>
      </form>

      <h2>Tasks List:</h2>
      <table>
        <thead>
          <tr>
            <th>Prospects</th>
            <th>Clients</th>
            <th>Leads</th>
            <th>Subject</th>
            <th>Target Date</th>
            <th>Priority</th>
            <th>Created At</th>
          </tr>
        </thead>
        <tbody>
          {tasks.map(task => (
            <tr key={task.id}>
              <td>{task.prospect_names}</td>
              <td>{task.client_names}</td>
              <td>{task.lead_names}</td>
              <td>{task.subject}</td>
              <td>{new Date(task.targetDate).toLocaleDateString()}</td>
              <td>{task.priority}</td>
              <td>{new Date(task.createdAt).toLocaleDateString()}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Tasks;
