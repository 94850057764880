import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './clients.css'; // Importing CSS file for styling
import AddClientForm from './addclientform';
import EditClientForm from './editclientform';

const apipath = process.env.REACT_APP_API_URL;

const Clients = () => {
  const [clients, setClients] = useState([]);
  const [showAddForm, setShowAddForm] = useState(false);
  const [editingClient, setEditingClient] = useState(null);

  // Fetch Clients from the server
  const fetchClients = async () => {
    try {
      const response = await axios.get(`${apipath}/clients`);
      const clientsData = response.data || [];
      console.log('Fetched Clients:', clientsData);
      setClients(clientsData);
    } catch (error) {
      console.error('Error fetching clients:', error);
      setClients([]); // Set clients to an empty array in case of an error
    }
  };

  useEffect(() => {
    fetchClients();
  }, []);

  // Handle adding a new client
  const handleAddClient = async (newClient) => {
    try {
      const response = await axios.post(`${apipath}/clients`, newClient);
      const addedClient = response.data.client;
      if (addedClient) {
        setClients(prevClients => [...prevClients, addedClient]);
        setShowAddForm(false);
      } else {
        console.error('Added client data is undefined');
      }
    } catch (error) {
      console.error('Error adding client:', error);
    }
  };

  // Handle updating a client
  const handleUpdateClient = async (updatedClient) => {
    try {
      const response = await axios.put(`${apipath}/clients/${updatedClient._id}`, updatedClient);
      const updatedClientData = response.data.client;
      if (updatedClientData) {
        setClients(prevClients =>
          prevClients.map(client =>
            client._id === updatedClientData._id ? updatedClientData : client
          )
        );
        setEditingClient(null);
      } else {
        console.error('Updated client data is undefined');
      }
    } catch (error) {
      console.error('Error updating client:', error);
    }
  };

  // Handle deleting a client
  const handleDeleteClient = async (clientId) => {
    const confirmed = window.confirm('Are you sure you want to delete this client?');
    if (!confirmed) return;

    try {
      await axios.delete(`${apipath}/clients/${clientId}`);
      setClients(prevClients => prevClients.filter(client => client._id !== clientId));
    } catch (error) {
      console.error('Error deleting client:', error);
    }
  };

  const handleRowClick = (client) => {
    setEditingClient(client);
  };

  return (
    <div className="clients-container">
      <h1>Clients</h1>
      <div className="clients-table-container">
        {!editingClient && (
          <button onClick={() => setShowAddForm(!showAddForm)}>Add Client</button>
        )}
        {showAddForm && !editingClient && (
          <AddClientForm onAddClient={handleAddClient} />
        )}
        {editingClient && (
          <EditClientForm
            client={editingClient}
            onUpdate={handleUpdateClient}
            onCancel={() => setEditingClient(null)}
          />
        )}
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>City</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(clients) && clients.length > 0 ? (
              clients.map(client => client && (
                <tr key={client._id} onClick={() => handleRowClick(client)}>
                  <td>{client.name || 'N/A'}</td>
                  <td>{client.email || 'N/A'}</td>
                  <td>{client.phone || 'N/A'}</td>
                  <td>{client.city || 'N/A'}</td>
                  <td>
                    <button onClick={(e) => {
                      e.stopPropagation();
                      setEditingClient(client);
                    }}>
                      Edit
                    </button>
                    <button onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteClient(client._id);
                    }}>
                      Delete
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5">No clients available</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Clients;
