import React from 'react';
import './pricing.css';
import Header from '../header/header';
import Footer from '../footer/footer'; // Assuming you have a Footer component

const PricingPage = () => {
  return (
    <div className="pricing-page-container">
      <Header />
      <div className="pricing-page">
        <h1>Our Pricing</h1>
        <p>Choose the plan that's right for your business.</p>
        <div className="pricing-tiers">
          <div className="tier">
            <h2>Basic</h2>
            <p className="price">$10/month</p>
            <ul>
              <li>Feature 1</li>
              <li>Feature 2</li>
              <li>Feature 3</li>
            </ul>
            <button>Get Started</button>
          </div>
          <div className="tier">
            <h2>Standard</h2>
            <p className="price">$20/month</p>
            <ul>
              <li>Feature 1</li>
              <li>Feature 2</li>
              <li>Feature 3</li>
              <li>Feature 4</li>
            </ul>
            <button>Get Started</button>
          </div>
          <div className="tier">
            <h2>Premium</h2>
            <p className="price">$30/month</p>
            <ul>
              <li>Feature 1</li>
              <li>Feature 2</li>
              <li>Feature 3</li>
              <li>Feature 4</li>
              <li>Feature 5</li>
            </ul>
            <button>Get Started</button>
          </div>
        </div>
      </div>
     
    </div>
  );
};

export default PricingPage;
